// AdminDashboard.js

import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import "../assets/css/checkoutCss.css";
import store from "../assets/images/stall1.png";
import donation from "../assets/images/donation1.png";
import succss from "../assets/images/success.png";
import imgbebx from "../assets/images/image29.png";

import grpCheckBanPc from "../assets/images/grpCheckBanPc.png";
import grpCheckBanMob from "../assets/images/grpCheckBanMob.png";


import BannerSmall from '../components/BannerSmall';
import {useNavigate} from "react-router-dom";
import {getRequest, postRequest} from "../routes/Routes";
import {loadBookingStatus} from "../common/common";

const GroupCheckout = () => {
    const navigate = useNavigate();
    const [booking, setBooking] = useState(null),
        [loading, setLoading] = useState(false),
        [loadingEmail, setLoadingEmail] = useState(false),
        [isPopupOpen, setPopupOpen] = useState(false),
        [bookingStatus, setBookingStatus] = useState(null);

    useEffect(() => {
        setBooking(JSON.parse(sessionStorage.getItem('groupBooking')));
        loadBookingStatus().then((data) => {
            setBookingStatus(data);
        }).catch(() => {
            setBookingStatus(null);
        });
    }, []);

    useEffect(() => {
        if (isPopupOpen) {
            document.ipgForm.submit();
        }
    }, [isPopupOpen]);

    const openPopup = () => {
        setLoading(true);
        let values = {
            ...booking,
            endpoint: '/api/booking/group'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    sessionStorage.removeItem('groupBooking');
                    setBooking({...response.data.booking, member_count: booking.member_count});
                    if (isPopupOpen) {
                        document.ipgForm.submit();
                    } else {
                        setPopupOpen(true);
                    }
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        });
    };

    const closePopups = () => {
        setLoadingEmail(true);
        getRequest('/api/booking/email/' + booking.id).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate('/');
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoadingEmail(false);
            setPopupOpen(false);
        });
    };

    const submitIPGForm = () => {
        document.ipgForm.submit();
    }

    if (booking) {
        return (
            <div className="admin-dashboard">
                <BannerSmall
                    backgroundImage={grpCheckBanMob}
                    backgroundPCImage={grpCheckBanPc}
                    mainText="Checkout"
                    paginationText="Home / Group Booking / checkout"

                />
                <div className="main-content">
                    <div className="cont-wrapper">
                        <div className="checkout-main-wrapper">
                            <div className="lef">
                                <span className="billInforSp">Billing Information</span>

                                <div className="leftSide-check">

                                    <div className="personDetail">

                                        <div className="lineSingle">
                                            <span className="lineRow-person">Full Name :</span>
                                            <span className="lineRow-person">{booking.full_name}</span>
                                        </div>

                                        <div className="lineSingle">
                                            <span className="lineRow-person">Name of Contact Person :</span>
                                            <span className="lineRow-person">{booking.person_name}</span>
                                        </div>

                                        <div className="lineSingle">
                                            <span className="lineRow-person">Contact Number :</span>
                                            <span className="lineRow-person">{booking.phone}</span>
                                        </div>

                                        <div className="lineSingle">
                                            <span className="lineRow-person">Email Address :</span>
                                            <span className="lineRow-person">{booking.email}</span>
                                        </div>
                                    </div>
                                    <div className="personDetail grpCat">
                                        <div className="lineSingle">
                                            <span className="lineRow-person">Group Category</span>
                                            <span className="lineRow-person">{booking.group_category}</span>
                                        </div>
                                    </div>
                                    <div className="tLableTxt">
                                        <span>T-Shirt Size Details</span>
                                    </div>
                                    <div className="tSize quntiLbl">
                                        <div className="lineSingle">
                                            <span className="lineRow-person">T-Shirt Size</span>
                                            <span className="lineRow-person">Quantity</span>
                                        </div>
                                    </div>
                                    <div className="registData tDatalbls">
                                        {booking.booking_t_shirts.map((item, index) => (
                                            <div className="lineSingle" key={index}>
                                            <span
                                                className="lineRow-person">{item.t_shirt_id ? item.t_shirt.description + " (" + item.t_shirt.size + ")" : "No T-Shirt"} :</span>
                                                <span
                                                    className="lineRow-person">{String(item.quantity).padStart(2, '0')}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="personDetail">
                                        <div className="lineSingle">
                                            <span className="lineRow-person">Total T-Shirt Quantity :</span>
                                            <span
                                                className="lineRow-person">{String(booking.member_count).padStart(2, '0')}</span>
                                        </div>
                                    </div>
                                    <div className="personDetail">
                                        <div className="lineSingle">
                                            <span className="lineRow-person">Registration Fee :</span>
                                            <span className="lineRow-person">LKR {booking.member_count * 1600}</span>
                                        </div>
                                        <div className="lineSingle">
                                            <span className="lineRow-person">Extra Donation :</span>
                                            <span
                                                className="lineRow-person">LKR {booking.donation - (booking.member_count * 1600)}</span>
                                        </div>
                                    </div>
                                    <div className="totDonation donGrp">
                                        <div className="lineSingle">
                                            <span className="lineRow-person">Donation Amount :</span>
                                            <span className="lineRow-person">LKR {booking.donation}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rgtSide-check">
                                <span className="billInforSp">Select Your Payment method</span>
                                <div className="cardInCashMain">
                                    <div className="flx-crd">
                                        <div className="lft">
                                            <span>Webxpay</span>
                                            <span>Safe payment online. Webxpay account is necessary.</span>
                                        </div>
                                        <div className="svgIco">
                                            <img src={imgbebx}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="payOptions">
                                    <div className="payOptionline">
                                        <div className="svgIcoPay">
                                            <img src={store}></img>
                                        </div>
                                        <div className="taglineOption">
                                            <span>The event will also feature stalls, interactive games, photo stations, and several entertainment items.</span>
                                        </div>
                                    </div>
                                    <div className="payOptionline">
                                        <div className="svgIcoPay">
                                            <img src={donation}></img>
                                        </div>
                                        <div className="taglineOption">
                                            <span>All proceeds from the event will be donated to the Apeksha Cancer Hospital in Maharagama</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="para-def-pay">
                                    <p>By clicking checkout button, you agree to with our terms & Conditions and also
                                        acknowledge that you can not change or alter any of the details you filled in
                                        this
                                        form after it is submitted. </p>
                                </div>
                                <button className="payButtonCheckout grpPay" type="submit" onClick={openPopup}
                                        disabled={bookingStatus && !bookingStatus.active}>
                                    {loading ?
                                        <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                              aria-hidden="true"/>
                                        : null
                                    }
                                    CHECKOUT
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Popup/Modal */}
                    {isPopupOpen && (
                        <div className="popupWindow" style={{display: "none"}}>
                            <div className="popup-content">
                                <img src={succss}></img>
                                <span className="popUpThanks">Thank You</span>
                                <span className="tagThanks">Your registration was received.</span>
                                <span className="spcTag">Thank you for registering for Run For Their Lives 2024 in aid of the Apeksha Cancer Hospital.</span>
                                <form action="https://webxpay.com/index.php?route=checkout/billing"
                                      name="ipgForm" onSubmit={submitIPGForm} method="post">
                                    <input hidden name="first_name" value={booking.person_name.split(" ")[0]} readOnly/>
                                    <input hidden name="last_name"
                                           value={booking.person_name.split(" ").length > 1 ? booking.person_name.split(" ")[1] : " "}
                                           readOnly/>
                                    <input hidden name="email" value={booking.email} readOnly/>
                                    <input hidden name="contact_number" value={booking.phone} readOnly/>
                                    <input hidden name="address_line_one" value="Colombo" readOnly/>
                                    <input hidden name="process_currency" value="LKR" readOnly/>
                                    <input hidden name="cms" value="PHP" readOnly/>
                                    <input hidden name="custom_fields" value={booking.custom_fields} readOnly/>
                                    <input hidden name="enc_method" value="JCs3J+6oSz4V0LgE0zi/Bg==" readOnly/>
                                    <input hidden name="secret_key" value={booking.secret_key} readOnly/>
                                    <input hidden name="payment" value={booking.payment} readOnly/>
                                    <button className="sendPop" type="submit"
                                            style={{marginBottom: 0}}>
                                        Continue to pay
                                    </button>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default GroupCheckout;
