import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import './App.css';
import Home from './views/Homepage';
import Indiv from './views/individualbooking';
import GrpReg from './views/GrpBookReg';
import IndiCheckout from './views/Checkout';
import GrpCheckout from './views/GroupCheckout';
import AboutUs from './views/AboutUs';
import Events from './views/Events';
import Gallery from './views/Gallery';
import NotFound from './views/PageNotFound';
import ContactUs from './views/Contactus';
import IndividualBookingSummary from './views/IndividualBookingSummary';
import PaymentFail from './views/PaymentFail';
import LoginPg from './views/loginPage';

function App() {
    return (
        <Router>
            <ScrollToTop>
                <div id="header">
                </div>
                <div id="headerandbody">
                    <Routes>
                        <Route exact path='/' element={<Home/>}/>
                        <Route path="/individual-booking" element={<Indiv/>}/>
                        <Route path="/group-booking" element={<GrpReg/>}/>
                        <Route path="/individual-booking/checkout" element={<IndiCheckout/>}/>
                        <Route path="/group-booking/checkout" element={<GrpCheckout/>}/>
                        <Route path="/about-us" element={<AboutUs/>}/>
                        <Route path="/events" element={<Events/>}/>
                        <Route path="/gallery" element={<Gallery/>}/>
                        <Route path="/404" element={<NotFound/>}/>
                        <Route path="/contact-us" element={<ContactUs/>}/>
                        <Route path="/booking/:bookingReference" element={<IndividualBookingSummary/>}/>
                        <Route path="/payment-failed" element={<PaymentFail/>}/>
                        <Route path='/client' element={<Navigate to='/'/>}/>
                        <Route exact path='/login'
                               element={<LoginPg emailLabel="Email" title="Welcome Back, Admin!"
                                                 usernameLabel="Username"
                                                 passwordLabel="Password"/>}/>
                        <Route path='*' element={<Navigate to='/404'/>}/>
                    </Routes>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>
                </div>
                <div id="footer">
                    <Footer/>
                </div>
            </ScrollToTop>
        </Router>
    );
}

export default App;
