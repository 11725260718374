import React, {useState} from "react";
import logo from '../assets/images/logo.png';
import '../assets/css/IndividualBookingSummary.css';
import {useNavigate} from "react-router-dom";
import {downloadFile, postRequest} from "../routes/Routes";
import {toast} from "react-toastify";

function GroupBookSummary({propBooking, user}) {
    const navigate = useNavigate(),
        [loading, setLoading] = useState(false),
        [checkinLoading, setCheckinLoading] = useState(false),
        [booking, setBooking] = useState({...propBooking, temp_checkin_count: propBooking.checkin_count});

    const download = () => {
        setLoading(true);
        downloadFile('/api/booking/download/' + booking.reference).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    response.data.arrayBuffer().then(function (buffer) {
                        const url = URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `booking.pdf`;
                        link.click();
                        URL.revokeObjectURL(url);
                    });
                    setLoading(false);
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    };

    const checkin = () => {
        setCheckinLoading(true);
        if (Number(booking.temp_checkin_count) === 0) {
            toast.error("Please enter a checkin count!", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setCheckinLoading(false);
            return;
        }
        let values = {
            token: user.token,
            checkin_count: Number(booking.temp_checkin_count),
            endpoint: '/api/booking/checkin/' + booking.id
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setBooking((prevData) => ({
                        ...prevData,
                        ...response.data.booking,
                    }))
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setCheckinLoading(false);
        });
    }

    return (
        <div className="summer-wrap">
            <img className="logo-pg" src={logo} alt=""/>
            <div className="book-confirm spc-chng">
                <span className="booking-confirm">Your Booking is confirmed!</span>
                <span className="para-nor">{booking.full_name}, thank you for registering for Run For Their Lives 2024 in aid of the Apeksha Cancer Hospital. Please note that all the proceeds from the event will be donated to the Apeksha Cancer Hospital in Maharagama</span>
                <span className="para-bold">Please make sure you have the receipt on hand when receiving the T-shirt at the event.</span>
            </div>
            <div className="bill-sum-wrapp">
                <div className="bill-head">
                    <div>
                        <span className="date">{(new Date(booking.created_at)).toDateString()}</span>
                        <span className="BillHead">Billing Summary</span>
                    </div>
                    <span className="BookingID">Booking ID : {booking.reference}</span>

                </div>

                <div className="flx-div">
                    <div className="sin">
                        <span className="cml-txt">Organization / Company / Group Name:</span>
                        <span className="big-txt">{booking.full_name}</span>
                    </div>
                    <div className="sin">
                        <span className="cml-txt">Name of Contact Person:</span>
                        <span className="big-txt">{booking.person_name}</span>
                    </div>
                    <div className="sin">
                        <span className="cml-txt">Contact Number:</span>
                        <span className="big-txt">{booking.phone}</span>
                    </div>
                    <div className="sin">
                        <span className="cml-txt">Email Address:</span>
                        <span className="big-txt">{booking.email}</span>
                    </div>
                </div>
                <div className="flx-sp-0">
                    <div className="div-box">
                        <span className="sin-m">Payment Method</span>
                        <span className="sin-b">{booking.payment_type}</span>
                    </div>

                </div>

                <div className="t-size">
                    <span className="t-lbl">T-shirt Size Details</span>

                    {booking.booking_t_shirts.map((item, index) => (
                        <div className="flx-main">
                            <div className="sml-fl">
                                <span className="nm-t">T-shirt Size :</span>
                                <span
                                    className="qt-t">{item.t_shirt_id ? item.t_shirt.description + " (" + item.t_shirt.size + ")" : "No T-Shirt"}</span>
                            </div>
                            <div className="sml-fl">
                                <span className="nm-t">Quantity :</span>
                                <span className="qt-t">{String(item.quantity).padStart(2, '0')}</span>
                            </div>
                        </div>
                    ))}

                    <div className="flx-main">
                        <div className="sml-fl">
                            <span className="nm-t">Total T-Shirt Quantity :</span>
                            <span className="qt-t">{String(booking.member_count).padStart(2, '0')}</span>
                        </div>
                    </div>
                </div>
                <div className="pay-dil">
                    <div className="sin-col">
                        <span className="reg-n">Registration Fee</span>
                        <span className="reg-c">LKR {booking.member_count * 1600}</span>
                    </div>
                    <div className="sin-col">
                        <span className="reg-n">Extra Donation</span>
                        <span className="reg-c">LKR {booking.donation - (booking.member_count * 1600)}</span>
                    </div>
                </div>

                <div className="total tsp">
                    <span className="name">Donation Amount</span>
                    <span className="fee">LKR {booking.donation}</span>
                </div>

                <div className="spc-div">
                    <span className="normal-pp">If you have any questions or inquiries, please don't hesitate to contact us via email <u>(rftl.charityrun@gmail.com)</u> or contact number (+94) 76 232 8714.</span>
                    <span className="thnks">Thank you for your contributions</span>
                    <span className="btm-nm">Rotaract Club Colombo Uptown (RCCU)</span>
                </div>
            </div>

            <button className="dwn dwnsp" onClick={() => download()}>
                {loading ?
                    <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                          aria-hidden="true"/>
                    : null
                }
                DOWNLOAD RECEIPT
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M2.5 15.8346H17.5V17.5013H2.5V15.8346ZM10.8333 10.9776L15.8926 5.91839L17.0711 7.0969L10 14.168L2.92893 7.0969L4.10744 5.91839L9.16667 10.9776V1.66797H10.8333V10.9776Z"
                        fill="white"/>
                </svg>
            </button>
            {user &&
            <>
                <div style={{width: "70%", margin: "1% auto 0"}}>
                    <span className="lft-tg">Checkin Count</span>
                    <input
                        type="number"
                        id="checkin_count"
                        name="checkin_count"
                        value={booking.temp_checkin_count}
                        onChange={(event) => setBooking((prevData) => ({
                            ...prevData,
                            temp_checkin_count: event.target.value,
                        }))}
                        placeholder="0"
                        min={booking.checkin_count}
                        max={booking.member_count}
                    />
                </div>
                <button className="dwn" onClick={() => checkin()}
                        disabled={booking.checkin && booking.checkin_count === booking.member_count}
                        style={{marginTop: "2%", backgroundColor: "#0b5ed7"}}>
                    {checkinLoading ?
                        <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                              aria-hidden="true"/>
                        : null
                    }
                    CHECK IN
                </button>
            </>
            }
            {booking.checkin &&
            <span className="cpy" style={{color: "red"}}>{booking.checkin_count} Members Already Checked In</span>
            }

            <span className="cpy">@ {new Date().getFullYear()} Copyright | All rights reserved</span>

            <div className="flx-ftr">
                <button className="nav-btn" onClick={() => navigate("/")}>Home</button>
                <button className="nav-btn" onClick={() => navigate("/about-us")}>About Us</button>
                <button className="nav-btn" onClick={() => navigate("/events")}>Events</button>
                <button className="nav-btn" onClick={() => navigate("/gallery")}>Gallery</button>
                <button className="nav-btn" onClick={() => navigate("/contact-us")}>Contact Us</button>
            </div>

        </div>
    )
}

export default GroupBookSummary

