import React, {useState} from "react";
import {toast} from "react-toastify";
import BannerSmall from '../components/BannerSmall';
import '../assets/css/contactUs.css';

import ConMobBan from "../assets/images/ContactMobBanner.jpg";
import ConPcBan from "../assets/images/ConPcBan.jpg";
import {postRequest} from "../routes/Routes";

function ContactUs() {
    const [activeIndex, setActiveIndex] = useState(null),
        [loading, setLoading] = useState(false);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const [formData, setFormData] = useState({
        fullName: '',
        contactNumber: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        let values = {
            name: formData.fullName,
            phone: formData.contactNumber,
            email: formData.email,
            message: formData.message,
            endpoint: '/api/contact-us'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        });
    };

    return (
        <div>
            <BannerSmall
                backgroundImage={ConMobBan}
                backgroundPCImage={ConPcBan}
                mainText="Contact us"
                paginationText="Home / Contact Us"

            />

            <div className="contctWrapper">
                <div className="small-card-flx">
                    <div className="single-card">
                        <div className="img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path
                                    d="M11.9999 13.4314C13.723 13.4314 15.1199 12.0345 15.1199 10.3114C15.1199 8.58828 13.723 7.19141 11.9999 7.19141C10.2768 7.19141 8.87988 8.58828 8.87988 10.3114C8.87988 12.0345 10.2768 13.4314 11.9999 13.4314Z"
                                    stroke="#A83FE5" stroke-width="1.5"/>
                                <path
                                    d="M3.6202 8.49C5.5902 -0.169998 18.4202 -0.159997 20.3802 8.5C21.5302 13.58 18.3702 17.88 15.6002 20.54C13.5902 22.48 10.4102 22.48 8.3902 20.54C5.6302 17.88 2.4702 13.57 3.6202 8.49Z"
                                    stroke="#A83FE5" stroke-width="1.5"/>
                            </svg>
                        </div>
                        <div className="txt">
                            <span className="main">Location</span>
                            <span className="small">Colombo, Sri Lanka</span>
                        </div>
                    </div>

                    <div className="single-card">
                        <div className="img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path
                                    d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
                                    stroke="#A83FE5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#A83FE5"
                                      stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className="txt">
                            <span className="main">Email Address</span>
                            <span className="small"><a href="mailto:rftl.charityrun@gmail.com"
                                                       style={{textDecoration: 'none'}}>rftl.charityrun@gmail.com</a></span>
                        </div>
                    </div>

                    <div className="single-card">
                        <div className="img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path
                                    d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                                    stroke="#A83FE5" stroke-width="1.5" stroke-miterlimit="10"/>
                                <path d="M18.5 9C18.5 8.4 18.03 7.48 17.33 6.73C16.69 6.04 15.84 5.5 15 5.5"
                                      stroke="#A83FE5" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M22 9C22 5.13 18.87 2 15 2" stroke="#A83FE5" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className="txt">
                            <span className="main">Phone Number</span>
                            <span className="small"><a href="tel:+94775466957" style={{textDecoration: 'none'}}>(+94) 77 546 6957</a></span>
                        </div>
                    </div>
                </div>

                <div className="bottom-main-flx">
                    <div className="lft">
                        <span className="lft-topic">Frequently Asked Questions</span>

                        <div className="dropdwn">
                            <div className="singleItem">
                                <div className="faq-item">
                                    <div className={`faq-question ${activeIndex === 0 ? 'open' : ''}`}
                                         onClick={() => toggleFAQ(0)}
                                    >
                                        Where will RFTL be held this time?
                                        <span className="arrow">{activeIndex === 0 ? '▼' : '▶'}</span>
                                    </div>
                                    {activeIndex === 0 && (
                                        <div className="faq-answer">
                                            The 11th edition of RFTL will be held at the Colombo Racecourse on the 25th
                                            of February 2024 from 7.30 am onwards.
                                        </div>
                                    )}
                                </div>

                                <div className="faq-item">
                                    <div className={`faq-question ${activeIndex === 5 ? 'open' : ''}`}
                                         onClick={() => toggleFAQ(5)}
                                    >
                                        What is the minimum donation to register for RFTL?
                                        <span className="arrow">{activeIndex === 5 ? '▼' : '▶'}</span>
                                    </div>
                                    {activeIndex === 5 && (
                                        <div className="faq-answer">
                                            The minimum donation is LKR 1600 per participant which will give you full
                                            event access and a complementary T-Shirt.
                                        </div>
                                    )}
                                </div>

                                <div className="faq-item">
                                    <div className={`faq-question ${activeIndex === 1 ? 'open' : ''}`}
                                         onClick={() => toggleFAQ(1)}
                                    >
                                        How can I collect my T-Shirt?
                                        <span className="arrow">{activeIndex === 1 ? '▼' : '▶'}</span>
                                    </div>
                                    {activeIndex === 1 && (
                                        <div className="faq-answer">
                                            You can collect your T-Shirt on the morning of the event after showing your
                                            receipt number to one of our counters.
                                        </div>
                                    )}
                                </div>

                                <div className="faq-item">
                                    <div className={`faq-question ${activeIndex === 2 ? 'open' : ''}`}
                                         onClick={() => toggleFAQ(2)}
                                    >
                                        What else will be there at the event?
                                        <span className="arrow">{activeIndex === 2 ? '▼' : '▶'}</span>
                                    </div>
                                    {activeIndex === 2 && (
                                        <div className="faq-answer">
                                            Look forward to plenty of entertainment including games, music, and special
                                            activities, along with refreshments galore!
                                        </div>
                                    )}
                                </div>

                                <div className="faq-item">
                                    <div className={`faq-question ${activeIndex === 3 ? 'open' : ''}`}
                                         onClick={() => toggleFAQ(3)}
                                    >
                                        I would like to be a part of RFTL as a sponsor / resource provider / volunteer
                                        <span className="arrow">{activeIndex === 3 ? '▼' : '▶'}</span>
                                    </div>
                                    {activeIndex === 3 && (
                                        <div className="faq-answer" style={{display: "block"}}>
                                            Kindly send us an email to <a href="mailto:rftl.charityrun@gmail.com"
                                                                          style={{textDecoration: 'none'}}>rftl.charityrun@gmail.com</a>.
                                            We would love to have
                                            your support in making RFTL 2024 a success!
                                        </div>
                                    )}
                                </div>

                                <div className="faq-item">
                                    <div className={`faq-question ${activeIndex === 6 ? 'open' : ''}`}
                                         onClick={() => toggleFAQ(6)}
                                    >
                                        I would like to donate towards the cause without receiving a T-Shirt or
                                        attending the event.
                                        <span className="arrow">{activeIndex === 6 ? '▼' : '▶'}</span>
                                    </div>
                                    {activeIndex === 6 && (
                                        <div className="faq-answer" style={{display: "block"}}>
                                            Donations towards the cause can be made by going to <a
                                            href="https://karuna.lk/program/rftl.html" target="_blank"
                                            style={{textDecoration: 'none'}}>https://karuna.lk/program/rftl.html</a>.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="rgt">
                        <span className="small-txt">Got any questions?</span>
                        <span className="big-txt">Send Us A Message</span>

                        <form onSubmit={handleSubmit}>
                            <div className="fDiv">
                                <label htmlFor="fullName">Full Name:</label>
                                <input
                                    type="text"
                                    id="fullName"
                                    name="fullName"
                                    value={formData.fullName}
                                    placeholder="Full Name"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="fDiv">
                                <label htmlFor="contactNumber">Contact Number:</label>
                                <input
                                    type="tel"
                                    id="contactNumber"
                                    placeholder="+94 11223344"
                                    name="contactNumber"
                                    value={formData.contactNumber}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="fDiv">
                                <label htmlFor="email">Email Address:</label>
                                <input
                                    type="email"
                                    placeholder="user@email.com"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="fDiv">
                                <label htmlFor="message">Message:</label>
                                <textarea
                                    id="message"
                                    placeholder="Type Here..."
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <button type="submit" className="btnFrmSub">
                                {loading ?
                                    <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                          aria-hidden="true"/>
                                    : null
                                }
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs

