import React, {useEffect, useState} from "react";
import BannerSmall from '../components/BannerSmall';
import '../assets/css/Gallery.css';
import galMobBan from '../assets/images/galMobBan.png'
import galPcBan from '../assets/images/galPcBan.jpg'
import {loadGalleryOnTags} from "../common/common";
import Loading from "../components/Loading";

function Gallery() {
    const [activeTab, setActiveTab] = useState('All'),
        [showMoreImages, setShowMoreImages] = useState(false),
        [gallery, setGallery] = useState(null),
        [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        loadGalleryOnTags().then(data => {
            setGallery(data);
            setLoading(false);
        }).catch(() => null);
    }, []);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setShowMoreImages(false);
    };

    const handleSeeMoreClick = () => {
        setShowMoreImages(true);
    };

    const handleShowLessClick = () => {
        setShowMoreImages(false);
    };

    if (!loading && gallery) {
        return (
            <div>
                <BannerSmall
                    backgroundImage={galMobBan}
                    backgroundPCImage={galPcBan}
                    mainText="Gallery"
                    paginationText="Home / Gallery"

                />
                <div className="galleryWrapper">
                    <span className="GalTop">Gallery</span>
                    <span className="miniTop">The journey so far...</span>

                    <div className="tabContent">
                        <div className="tabs">
                            <button onClick={() => handleTabChange('All')}
                                    className={activeTab === 'All' ? 'active' : ''}>
                                ALL
                            </button>
                            {gallery.tags.map((item, index) => (
                                <button key={"tag" + index} onClick={() => handleTabChange(item.tag)}
                                        className={activeTab === item.tag ? 'active' : ''}>
                                    {item.tag}
                                </button>
                            ))}
                        </div>

                        <div className="tab-content">
                            {activeTab === 'All' && (
                                <>
                                    <div className="all-img-wrapper">
                                        {showMoreImages ? (
                                            gallery.all.map((item, index) => (
                                                <img key={"allImage" + index} src={item.image_url} alt={item.tag}/>
                                            ))
                                        ) : gallery.all.slice(0, 6).map((item, index) => (
                                            <img key={"allImage" + index} src={item.image_url} alt={item.tag}/>
                                        ))}
                                    </div>
                                    {showMoreImages && (
                                        <button onClick={handleShowLessClick} className="ShowBtnSp">Show Less
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 viewBox="0 0 16 16" fill="none">
                                                <path
                                                    d="M4 6.22076V9.78076C4 11.9941 5.56667 12.8941 7.48 11.7941L8.33333 11.3008C8.54 11.1808 8.66667 10.9608 8.66667 10.7208V5.28076C8.66667 5.04076 8.54 4.82076 8.33333 4.70076L7.48 4.20743C5.56667 3.10743 4 4.00743 4 6.22076Z"
                                                    fill="white"/>
                                                <path
                                                    d="M9.3335 5.86102V10.1477C9.3335 10.4077 9.6135 10.5677 9.8335 10.4343L10.5668 10.0077C12.4802 8.90768 12.4802 7.09435 10.5668 5.99435L9.8335 5.56768C9.6135 5.44102 9.3335 5.60102 9.3335 5.86102Z"
                                                    fill="white"/>
                                            </svg>
                                        </button>
                                    )}
                                    {!showMoreImages && (
                                        <button onClick={handleSeeMoreClick} className="ShowBtnSp">Show More
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 viewBox="0 0 16 16" fill="none">
                                                <path
                                                    d="M4 6.22076V9.78076C4 11.9941 5.56667 12.8941 7.48 11.7941L8.33333 11.3008C8.54 11.1808 8.66667 10.9608 8.66667 10.7208V5.28076C8.66667 5.04076 8.54 4.82076 8.33333 4.70076L7.48 4.20743C5.56667 3.10743 4 4.00743 4 6.22076Z"
                                                    fill="white"/>
                                                <path
                                                    d="M9.3335 5.86102V10.1477C9.3335 10.4077 9.6135 10.5677 9.8335 10.4343L10.5668 10.0077C12.4802 8.90768 12.4802 7.09435 10.5668 5.99435L9.8335 5.56768C9.6135 5.44102 9.3335 5.60102 9.3335 5.86102Z"
                                                    fill="white"/>
                                            </svg>
                                        </button>
                                    )}
                                </>
                            )}
                            {activeTab !== 'All' && (
                                <>
                                    <div className="all-img-wrapper">
                                        {showMoreImages ? (
                                            gallery[activeTab].map((item, index) => (
                                                <img key={"allImage" + index} src={item.image_url} alt={item.tag}/>
                                            ))
                                        ) : gallery[activeTab].slice(0, 6).map((item, index) => (
                                            <img key={"allImage" + index} src={item.image_url} alt={item.tag}/>
                                        ))}
                                    </div>
                                    {showMoreImages && (
                                        <button onClick={handleShowLessClick} className="ShowBtnSp">Show Less
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 viewBox="0 0 16 16" fill="none">
                                                <path
                                                    d="M4 6.22076V9.78076C4 11.9941 5.56667 12.8941 7.48 11.7941L8.33333 11.3008C8.54 11.1808 8.66667 10.9608 8.66667 10.7208V5.28076C8.66667 5.04076 8.54 4.82076 8.33333 4.70076L7.48 4.20743C5.56667 3.10743 4 4.00743 4 6.22076Z"
                                                    fill="white"/>
                                                <path
                                                    d="M9.3335 5.86102V10.1477C9.3335 10.4077 9.6135 10.5677 9.8335 10.4343L10.5668 10.0077C12.4802 8.90768 12.4802 7.09435 10.5668 5.99435L9.8335 5.56768C9.6135 5.44102 9.3335 5.60102 9.3335 5.86102Z"
                                                    fill="white"/>
                                            </svg>
                                        </button>
                                    )}
                                    {!showMoreImages && (
                                        <button onClick={handleSeeMoreClick} className="ShowBtnSp">Show More
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 viewBox="0 0 16 16" fill="none">
                                                <path
                                                    d="M4 6.22076V9.78076C4 11.9941 5.56667 12.8941 7.48 11.7941L8.33333 11.3008C8.54 11.1808 8.66667 10.9608 8.66667 10.7208V5.28076C8.66667 5.04076 8.54 4.82076 8.33333 4.70076L7.48 4.20743C5.56667 3.10743 4 4.00743 4 6.22076Z"
                                                    fill="white"/>
                                                <path
                                                    d="M9.3335 5.86102V10.1477C9.3335 10.4077 9.6135 10.5677 9.8335 10.4343L10.5668 10.0077C12.4802 8.90768 12.4802 7.09435 10.5668 5.99435L9.8335 5.56768C9.6135 5.44102 9.3335 5.60102 9.3335 5.86102Z"
                                                    fill="white"/>
                                            </svg>
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<Loading/>);
    }
}

export default Gallery

