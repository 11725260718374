import React from "react";

import '../assets/css/notFound.css'; 
import bannerMob from '../assets/images/notFoundMobile.png'; 
import bannerPc from '../assets/images/notFoundPc.png';  


const NotFound = () => {
    const backToHome = () => {
        window.location.href = "/";
    };
    return (
      <div className="NotFoundMainWrapper">
        <picture>
              <source media="(max-width: 767px)" srcSet={bannerMob} alt=""/>
              <source media="(max-width: 1023px)" srcSet={bannerMob} alt=""/>
              <img src={bannerPc} alt="background"/>
          </picture>
          <div className="abslTxt">
            <span className="num">404</span>
            <span className="main">Page Is Not Found</span>
            <span className="small">Sorry, the page you requested could not be found</span>
            <button className="backToHome" onClick={backToHome}>Back To Home 
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M4 6.22076V9.78076C4 11.9941 5.56667 12.8941 7.48 11.7941L8.33333 11.3008C8.54 11.1808 8.66667 10.9608 8.66667 10.7208V5.28076C8.66667 5.04076 8.54 4.82076 8.33333 4.70076L7.48 4.20743C5.56667 3.10743 4 4.00743 4 6.22076Z" fill="white"/>
                    <path d="M9.33301 5.86102V10.1477C9.33301 10.4077 9.61301 10.5677 9.83301 10.4343L10.5663 10.0077C12.4797 8.90768 12.4797 7.09435 10.5663 5.99435L9.83301 5.56768C9.61301 5.44102 9.33301 5.60102 9.33301 5.86102Z" fill="white"/>
                </svg>
            </button>
          </div>
      </div>
    )
}
export default NotFound;