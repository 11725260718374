import React, {useEffect, useState} from 'react';
import '../assets/css/OurPartners.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {loadPartners} from "../common/common";
import Loading from "./Loading";

function OurPartners() {
    const [partners, setPartners] = useState([]),
        [loading, setLoading] = useState(false);

    const sliderSettings = {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        pauseOnHover: false,
        centerPadding: '30px',
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        setLoading(true);
        loadPartners().then(data => {
            setPartners(data);
            setLoading(false);
        }).catch(() => null);
    }, []);

    return (
        <div>
            <section className="OurPartnerMain">
                <div className="partner-header">
                    <span>Our Partners</span>
                </div>
                <div className="partnerBody">
                    <div className="Slider">
                        {loading ? <Loading/>
                            : <Slider {...sliderSettings}>
                                {partners.map((item, index) => (
                                    <div key={"partner" + index}>
                                        <img src={item.image_url} alt={item.name}/>
                                    </div>
                                ))}
                            </Slider>
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default OurPartners
