import React, {useEffect, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/css/HomePage.css';
import itfImg from '../assets/images/itfImg.jpg';
import {Button} from "react-bootstrap";
import EventBanner from "../components/EventBanner";
import OurPartners from "../components/OurPartners";

import run from '../assets/images/bottomBannermobileImg.jpg';
import runpc from '../assets/images/runpc.jpg';

import Header from '../components/Header';
import {loadLimitedGalleries} from "../common/common";
import {Link} from "react-router-dom";
import Loading from "../components/Loading";

function HomePage() {
    const [gallery, setGallery] = useState([]),
        [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        loadLimitedGalleries(3).then(data => {
            setGallery(data);
            setLoading(false);
        }).catch(() => null);
    }, []);

    return (
        <div>
            <Header/>
            {/* start number section */}
            <section className="hm-number-count">
                <div className="hm-main-wrap-count">
                    <div className="hm-flex-con">
                        {/* define single number */}
                        <div className="hm-single-bx">
                            <div className="hm-num-n">
                                <div className="hm-nmbr">10+</div>
                                <div className="hm-tagline">Years of consecutive success</div>
                            </div>
                        </div>

                        {/* define single number */}
                        <div className="hm-single-bx">
                            <div className="hm-num-n">
                                <div className="hm-nmbr">18K+</div>
                                <div className="hm-tagline">Participants over the years</div>
                            </div>
                        </div>

                        {/* define single number */}
                        <div className="hm-single-bx">
                            <div className="hm-num-n">
                                <div className="hm-nmbr">40M+</div>
                                <div className="hm-tagline">Donations raised for charity over the Years</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* start image-text flex section */}
            <section className="hm-imgtxt-flex">
                <div className="hm-itf-main">
                    <div className="hm-itf-left">
                        <span className="hm-itf-tgln">Our Story</span>
                        <h2 className="hm-itf-boldtxt">
                            10 Year Long Journey Since The Inception of RACCU To Now
                        </h2>
                        <div className="-hm-itf-para">
                            <p className="hm-itf-common">
                                Chartered in 1995 and revived in 2010, Rotaract Club of Colombo Uptown (RACCU) is a
                                Rotary-sponsored, community-based service club, based in Colombo, Sri Lanka.
                            </p>
                            <p className="hm-itf-common">
                                Dedicated to personal, professional and community development, RACCU has been in the
                                forefront of Rotaract Sri Lanka, organizing many worthy projects of which the following
                                are renown for their results:
                            </p>
                            <ul className="hm-itf-ul">
                                <li>
                                    - Run For Their Lives (RFTL)
                                </li>
                                <li>
                                    - Anonymous Hope- Can't Dodge This
                                </li>
                                <li>
                                    - Dodgeball Tournament
                                </li>
                                <li>
                                    - Beyond the Pedals
                                </li>
                            </ul>
                            <p className="hm-itf-common">
                                The revival of the RACCU was a great phenomenon for the Rotaract movement in Sri Lanka,
                                which soon after witnessed the reward of hard work and dedication when it received its
                                first Rotary International Award in 2011.
                            </p>
                        </div>
                        <Link style={{textDecoration: 'none'}} to={'/about-us'}>
                            <Button className="hm-itf-btn">
                                Learn More
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                     fill="none">
                                    <path
                                        d="M4 6.22V9.78C4 11.9933 5.56667 12.8933 7.48 11.7933L8.33333 11.3C8.54 11.18 8.66667 10.96 8.66667 10.72V5.28C8.66667 5.04 8.54 4.82 8.33333 4.7L7.48 4.20667C5.56667 3.10667 4 4.00667 4 6.22Z"
                                        fill="white"/>
                                    <path
                                        d="M9.33331 5.86001V10.1467C9.33331 10.4067 9.61331 10.5667 9.83331 10.4333L10.5666 10.0067C12.48 8.90667 12.48 7.09334 10.5666 5.99334L9.83331 5.56668C9.61331 5.44001 9.33331 5.60001 9.33331 5.86001Z"
                                        fill="white"/>
                                </svg>
                            </Button>
                        </Link>
                    </div>
                    <div className="hm-itf-right">
                        <img src={itfImg} alt="story"></img>
                    </div>
                </div>
            </section>
            <EventBanner/>
            <section className="gallery-section">
                <div className="main-div-gallery">
                    <div className="gallery-header">
                        <span className="big-header">GALLERY</span>
                        <span className="small-tg-gal">The Journey So Far...</span>
                    </div>
                    <div className="gallery-body">
                        {loading ? (<Loading/>)
                            : gallery.map((item, index) => (
                                <img key={"gallery" + index} src={item.image_url} alt={item.tag}/>
                            ))}
                    </div>
                    <div className="button-for-gal">
                        <Link style={{textDecoration: 'none'}} to={'gallery'}>
                            <Button className="hm-itf-btn">
                                Show More
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                     fill="none">
                                    <path
                                        d="M4 6.22V9.78C4 11.9933 5.56667 12.8933 7.48 11.7933L8.33333 11.3C8.54 11.18 8.66667 10.96 8.66667 10.72V5.28C8.66667 5.04 8.54 4.82 8.33333 4.7L7.48 4.20667C5.56667 3.10667 4 4.00667 4 6.22Z"
                                        fill="white"/>
                                    <path
                                        d="M9.33331 5.86001V10.1467C9.33331 10.4067 9.61331 10.5667 9.83331 10.4333L10.5666 10.0067C12.48 8.90667 12.48 7.09334 10.5666 5.99334L9.83331 5.56668C9.61331 5.44001 9.33331 5.60001 9.33331 5.86001Z"
                                        fill="white"/>
                                </svg>
                            </Button>
                        </Link>
                    </div>
                </div>
            </section>

            <section className="EvntBottom">
                <div className="background">
                    <picture>
                        <source media="(max-width: 767px)" srcSet={run} alt=""/>
                        <source media="(max-width: 1023px)" srcSet={runpc} alt=""/>
                        <img src={runpc} alt="background"/>
                    </picture>
                    <div className="background-txt">
                        <div className="event-hdr">
                            <span>Run for their Lives (RFTL) - Annual Charity Run</span>
                        </div>
                        <p className="eventBannerPara">
                            Colombo's highly anticipated annual charity run organized by the Rotaract Club of Colombo
                            Uptown with all proceeds from the event <br/> donated to the Apeksha Cancer Hospital in
                            Maharagama.
                        </p>

                        <a href="tel:+94775466957" style={{textDecoration: 'none'}}>
                            <Button className="hm-itf-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16"
                                     fill="none">
                                    <path
                                        d="M12.2467 7.16771C11.96 7.16771 11.7334 6.93437 11.7334 6.65437C11.7334 6.40771 11.4867 5.89438 11.0734 5.44771C10.6667 5.01438 10.22 4.76104 9.84671 4.76104C9.56004 4.76104 9.33337 4.52771 9.33337 4.24771C9.33337 3.96771 9.56671 3.73438 9.84671 3.73438C10.5134 3.73438 11.2134 4.09438 11.8267 4.74104C12.4 5.34771 12.7667 6.10104 12.7667 6.64771C12.7667 6.93438 12.5334 7.16771 12.2467 7.16771Z"
                                        fill="white"/>
                                    <path
                                        d="M14.6533 7.16536C14.3666 7.16536 14.14 6.93203 14.14 6.65203C14.14 4.28536 12.2133 2.36536 9.8533 2.36536C9.56663 2.36536 9.33997 2.13203 9.33997 1.85203C9.33997 1.57203 9.56663 1.33203 9.84663 1.33203C12.78 1.33203 15.1666 3.7187 15.1666 6.65203C15.1666 6.93203 14.9333 7.16536 14.6533 7.16536Z"
                                        fill="white"/>
                                    <path
                                        d="M7.86671 9.96536L6.63337 11.1987C6.37337 11.4587 5.96004 11.4587 5.69337 11.2054C5.62004 11.132 5.54671 11.0654 5.47337 10.992C4.78671 10.2987 4.16671 9.57203 3.61337 8.81203C3.06671 8.05203 2.62671 7.29203 2.30671 6.5387C1.99337 5.7787 1.83337 5.05203 1.83337 4.3587C1.83337 3.90536 1.91337 3.47203 2.07337 3.07203C2.23337 2.66536 2.48671 2.29203 2.84004 1.9587C3.26671 1.5387 3.73337 1.33203 4.22671 1.33203C4.41337 1.33203 4.60004 1.37203 4.76671 1.45203C4.94004 1.53203 5.09337 1.65203 5.21337 1.82536L6.76004 4.00536C6.88004 4.17203 6.96671 4.32536 7.02671 4.47203C7.08671 4.61203 7.12004 4.75203 7.12004 4.8787C7.12004 5.0387 7.07337 5.1987 6.98004 5.35203C6.89337 5.50536 6.76671 5.66536 6.60671 5.82536L6.10004 6.35203C6.02671 6.42536 5.99337 6.51203 5.99337 6.6187C5.99337 6.67203 6.00004 6.7187 6.01337 6.77203C6.03337 6.82536 6.05337 6.86536 6.06671 6.90536C6.18671 7.12536 6.39337 7.41203 6.68671 7.7587C6.98671 8.10536 7.30671 8.4587 7.65337 8.81203C7.72004 8.8787 7.79337 8.94536 7.86004 9.01203C8.12671 9.27203 8.13337 9.6987 7.86671 9.96536Z"
                                        fill="white"/>
                                    <path
                                        d="M15.1467 12.2194C15.1467 12.406 15.1134 12.5994 15.0467 12.786C15.0267 12.8394 15.0067 12.8927 14.98 12.946C14.8667 13.186 14.72 13.4127 14.5267 13.626C14.2 13.986 13.84 14.246 13.4334 14.4127C13.4267 14.4127 13.42 14.4194 13.4134 14.4194C13.02 14.5794 12.5934 14.666 12.1334 14.666C11.4534 14.666 10.7267 14.506 9.96002 14.1794C9.19336 13.8527 8.42669 13.4127 7.66669 12.8594C7.40669 12.666 7.14669 12.4727 6.90002 12.266L9.08002 10.086C9.26669 10.226 9.43336 10.3327 9.57336 10.406C9.60669 10.4194 9.64669 10.4394 9.69336 10.4594C9.74669 10.4794 9.80002 10.486 9.86002 10.486C9.97336 10.486 10.06 10.446 10.1334 10.3727L10.64 9.87271C10.8067 9.70604 10.9667 9.57938 11.12 9.49938C11.2734 9.40604 11.4267 9.35938 11.5934 9.35938C11.72 9.35938 11.8534 9.38604 12 9.44604C12.1467 9.50604 12.3 9.59271 12.4667 9.70604L14.6734 11.2727C14.8467 11.3927 14.9667 11.5327 15.04 11.6994C15.1067 11.866 15.1467 12.0327 15.1467 12.2194Z"
                                        fill="white"/>
                                </svg>
                                Reach out to us
                            </Button>
                        </a>
                    </div>
                </div>
            </section>
            <OurPartners/>
        </div>
    )
}

export default HomePage

