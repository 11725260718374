import React from 'react';

import '../assets/css/Loading.css';

function Loading() {
    return (
        <div className="container loading">
            <span className="spinner-grow" role="status" aria-hidden="true"/>
        </div>
    );
}

export default Loading;
