import React from 'react';
import '../assets/css/EventBanner.css';
import iventMobBanner from '../assets/images/eventBannerImage.jpg'
import iventpcBanner from '../assets/images/iventpcimg.jpg'

function EventBanner() {
    return (
        <div>
            <section className="Evnt">
                <div className="background">
                    <picture>
                        <source media="(max-width: 767px)" srcSet={iventMobBanner} alt=""/>
                        <source media="(max-width: 1023px)" srcSet={iventMobBanner} alt=""/>
                        <img src={iventpcBanner} alt="background"/>
                    </picture>
                    <div className="background-txt">
                        <div className="small-txt-line">
                            <h4>Let's <span className="color-change-word">
                            Make
                            </span>{' '} a difference, One <span className="color-change-word">
                            Step
                            </span>{' '} At A Time</h4>
                        </div>
                        <div className="event-hdr">
                            <span>Upcoming Events</span>
                        </div>
                        <p className="eventBannerPara">
                            Inaugurated in 2010, Run For Their Lives (RFTL) is an annual 5km charity run in aid of the
                            Apeksha Cancer Hospital in Maharagama.<br/> RFTL will be coming your way for the 11th time
                            in February 2024.
                        </p>
                        <p className="eventBannerPara">
                            25th February 2024, Colombo Racecourse, 7:30 AM Onwards
                        </p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EventBanner
