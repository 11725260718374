import React from "react";
import '../assets/css/HowItWorks.css'

function HowItWorks() {
    return (

        <div className="howItWorks-Wrapper pprbc">
            <span className="main-topic">How it works</span>

            <div className="comp-flex-all">
                <div className="Single-main-div">
                    <div className="number ">
                    {/* to get purpole color rounds and line add, "purpleNumber" class with "number" class*/}
                        <span>1</span>
                    </div>
                    <div className="inner-flex">
                        <div className="main-txt">
                            <span>Fill in the registration form for individual participant</span>
                        </div>
                        <div className="para">
                            <span>Welcome to RFTL -2024. Fill in the registration form below if you are planning on participating in RFTL - 2024 Charity run as an individual participant.</span>
                        </div>
                    </div>
                </div>

                <div className="Single-main-div">
                    <div className="number">
                        <span>2</span>
                    </div>
                    <div className="inner-flex">
                        <div className="main-txt">
                            <span>Select option for T-shirt & extra donations</span>
                        </div>
                        <div className="para">
                            <span>Participant can select the T-shirt size out of the list & also 'No T-shirt'. Minimum donation amount is LKR 1600 with or without T-shirt.</span>
                        </div>
                    </div>
                </div>

                <div className="Single-main-div">
                    <div className="number">
                        <span>3</span>
                    </div>
                    <div className="inner-flex">
                        <div className="main-txt">
                            <span>Proceed to checkout & Select payment method</span>
                        </div>
                        <div className="para">
                            <span>Payment can be made through the WebXPay payments platform, which facilitates multiple forms of payment including credit cards, debit cards and e-wallets.</span>
                        </div>
                    </div>
                </div>

                <div className="Single-main-div">
                    <div className="number">
                        <span>4</span>
                    </div>
                    <div className="inner-flex">
                        <div className="main-txt">
                            <span>Receipt will be sent to your inbox</span>
                        </div>
                        <div className="para">
                            <span>Your receipt will be sent to your inbox shortly after registering. Please make sure you have the receipt on hand when receiving the T-shirt at the event.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks
