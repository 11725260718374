import React, {useEffect, useState} from "react";
import '../assets/css/Footer.css'
import logo from '../assets/images/logo.png'
import {Link} from 'react-router-dom';
import {deleteUser, getUser} from "../auth/Auth";

function Footer() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
        }).catch(() => {
            setUser(null);
        });
    }, []);

    const handleLogout = () => {
        deleteUser().then(() => {
            window.location.href = '/login';
        });
    };

    return (
        <div className="FooterWrapper">
            <div className="main-3-col-wrapper">
                <div className="left-main">
                    <img src={logo}/>

                    <span className="lft-main-txt">
                        Colombo's highly anticipated annual charity run organized by the Rotaract Club of Colombo Uptown with 100% of event proceeds being donated to the Apeksha Cancer Institute in Maharagama.
                    </span>
                </div>
                <div className="rgt-main">
                    <div className="rgt-lft">
                        <span className="small-heading">Site map</span>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/about-us">About Us</Link>
                            </li>
                            <li>
                                <Link to="/events">Events</Link>
                            </li>
                            <li>
                                <Link to="/gallery">Gallery</Link>
                            </li>
                            <li>
                                <Link to="/contact-us">Contact Us</Link>
                            </li>
                            {user &&
                            <li>
                                <Link to="/" onClick={() => handleLogout()}>{user.name}</Link>
                            </li>
                            }
                        </ul>
                    </div>
                    <div className="rgt-rgt">
                        <span className="small-heading">Contact Details</span>

                        <div className="flx-div">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none">
                                <path
                                    d="M14.1666 17.0846H5.83329C3.33329 17.0846 1.66663 15.8346 1.66663 12.918V7.08464C1.66663 4.16797 3.33329 2.91797 5.83329 2.91797H14.1666C16.6666 2.91797 18.3333 4.16797 18.3333 7.08464V12.918C18.3333 15.8346 16.6666 17.0846 14.1666 17.0846Z"
                                    stroke="#E53F71" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M14.1667 7.5L11.5584 9.58333C10.7 10.2667 9.2917 10.2667 8.43337 9.58333L5.83337 7.5"
                                    stroke="#E53F71" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                            <div className="double">
                                <span className="boldtxt">Email Address</span>
                                <span className="thintxt"><a href="mailto:rftl.charityrun@gmail.com"
                                                             style={{textDecoration: 'none'}}>rftl.charityrun@gmail.com</a></span>
                            </div>
                        </div>

                        <div className="flx-div">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none">
                                <path
                                    d="M18.3083 15.2763C18.3083 15.5763 18.2416 15.8846 18.1 16.1846C17.9583 16.4846 17.775 16.768 17.5333 17.0346C17.125 17.4846 16.675 17.8096 16.1666 18.018C15.6666 18.2263 15.125 18.3346 14.5416 18.3346C13.6916 18.3346 12.7833 18.1346 11.825 17.7263C10.8666 17.318 9.90829 16.768 8.95829 16.0763C7.99996 15.3763 7.09163 14.6013 6.22496 13.743C5.36663 12.8763 4.59163 11.968 3.89996 11.018C3.21663 10.068 2.66663 9.11797 2.26663 8.1763C1.86663 7.2263 1.66663 6.31797 1.66663 5.4513C1.66663 4.88464 1.76663 4.34297 1.96663 3.84297C2.16663 3.33464 2.48329 2.86797 2.92496 2.4513C3.45829 1.9263 4.04163 1.66797 4.65829 1.66797C4.89163 1.66797 5.12496 1.71797 5.33329 1.81797C5.54996 1.91797 5.74163 2.06797 5.89163 2.28464L7.82496 5.00964C7.97496 5.21797 8.08329 5.40964 8.15829 5.59297C8.23329 5.76797 8.27496 5.94297 8.27496 6.1013C8.27496 6.3013 8.21663 6.5013 8.09996 6.69297C7.99163 6.88464 7.83329 7.08464 7.63329 7.28464L6.99996 7.94297C6.90829 8.03464 6.86663 8.14297 6.86663 8.2763C6.86663 8.34297 6.87496 8.4013 6.89163 8.46797C6.91663 8.53464 6.94163 8.58463 6.95829 8.63463C7.10829 8.90963 7.36663 9.26797 7.73329 9.7013C8.10829 10.1346 8.50829 10.5763 8.94163 11.018C9.39163 11.4596 9.82496 11.868 10.2666 12.243C10.7 12.6096 11.0583 12.8596 11.3416 13.0096C11.3833 13.0263 11.4333 13.0513 11.4916 13.0763C11.5583 13.1013 11.625 13.1096 11.7 13.1096C11.8416 13.1096 11.95 13.0596 12.0416 12.968L12.675 12.343C12.8833 12.1346 13.0833 11.9763 13.275 11.8763C13.4666 11.7596 13.6583 11.7013 13.8666 11.7013C14.025 11.7013 14.1916 11.7346 14.375 11.8096C14.5583 11.8846 14.75 11.993 14.9583 12.1346L17.7166 14.093C17.9333 14.243 18.0833 14.418 18.175 14.6263C18.2583 14.8346 18.3083 15.043 18.3083 15.2763Z"
                                    stroke="#E53F71" stroke-width="1.5" stroke-miterlimit="10"/>
                                <path
                                    d="M15.4167 7.5026C15.4167 7.0026 15.025 6.23594 14.4417 5.61094C13.9083 5.03594 13.2 4.58594 12.5 4.58594"
                                    stroke="#E53F71" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18.3333 7.5013C18.3333 4.2763 15.725 1.66797 12.5 1.66797" stroke="#E53F71"
                                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div className="double">
                                <span className="boldtxt">Phone Number</span>
                                <span className="thintxt"><a href="tel:+94775466957" style={{textDecoration: 'none'}}>(+94) 77 546 6957</a></span>
                            </div>
                        </div>

                        <div className="flx-div">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none">
                                <path
                                    d="M10 11.1922C11.436 11.1922 12.6 10.0281 12.6 8.59219C12.6 7.15625 11.436 5.99219 10 5.99219C8.56408 5.99219 7.40002 7.15625 7.40002 8.59219C7.40002 10.0281 8.56408 11.1922 10 11.1922Z"
                                    stroke="#E53F71" stroke-width="1.5"/>
                                <path
                                    d="M3.01663 7.0763C4.65829 -0.140363 15.35 -0.132029 16.9833 7.08464C17.9416 11.318 15.3083 14.9013 13 17.118C11.325 18.7346 8.67496 18.7346 6.99163 17.118C4.69163 14.9013 2.05829 11.3096 3.01663 7.0763Z"
                                    stroke="#E53F71" stroke-width="1.5"/>
                            </svg>
                            <div className="double">
                                <span className="boldtxt">Location</span>
                                <span className="thintxt">Colombo, Sri Lanka</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path
                        d="M6.99996 12.8346C3.77996 12.8346 1.16663 10.2213 1.16663 7.0013C1.16663 3.7813 3.77996 1.16797 6.99996 1.16797C10.22 1.16797 12.8333 3.7813 12.8333 7.0013C12.8333 10.2213 10.22 12.8346 6.99996 12.8346Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M8.67998 8.7513C8.26582 9.11297 7.72914 9.33464 7.13997 9.33464C5.85081 9.33464 4.80664 8.29047 4.80664 7.0013C4.80664 5.71214 5.85081 4.66797 7.13997 4.66797C7.72914 4.66797 8.26582 4.88964 8.67998 5.2513"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span className="copy">
                    {new Date().getFullYear()} Copyright | Website developed by <a href="https://yellawt.com/" style={{
                    textDecoration: 'none',
                    color: '#FFFF'
                }} target="_blank">Yellawt</a> in collaboration with <a href="https://eccelleroit.com/" target="_blank"
                                                                        style={{
                                                                            textDecoration: 'none',
                                                                            color: '#FFFF'
                                                                        }}>Eccellero</a> | All rights reserved
                </span>
            </div>
        </div>
    )
}

export default Footer
