import React from "react";
import {Button, Container, Nav, Navbar} from 'react-bootstrap';
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import '../assets/css/Header.css';
import LOGO from '../assets/images/logo.png'

function SeparateMenu() {
    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="custom-navbar nv-height">
            <Container>
                {/* Left Side Icon */}
                <Navbar.Brand href="/">
                    <img
                        src={LOGO}
                        alt="Logo"
                        className="logo"
                        style={{marginRight: '10px'}}
                    />
                </Navbar.Brand>
                {/* Middle Navigation Menu Items */}
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto mdl-set">
                        <Nav.Link href="/" className="menu-item " activeClassName="active">
                            Home
                        </Nav.Link>
                        <Nav.Link href="/about-us" className="menu-item" activeClassName="active">
                            About Us
                        </Nav.Link>
                        <Nav.Link href="/events" className="menu-item" activeClassName="active">
                            Events
                        </Nav.Link>
                        <Nav.Link href="/gallery" className="menu-item" activeClassName="active">
                            Gallery
                        </Nav.Link>
                        <Nav.Link href="/contact-us" className="menu-item" activeClassName="active">
                            Contact Us
                        </Nav.Link>
                    </Nav>
                    <Nav className="ml-auto rgt-set">
                        <Nav.Link href="#" className="follow-us lnv">
                            Follow Us
                        </Nav.Link>
                        <Nav.Link href="https://www.facebook.com/runfortheirlives/" target="_blank"
                                  className="social-icon lnv">
                            <FaFacebook style={{width: '20px', height: '20px'}}/>
                        </Nav.Link>
                        <Nav.Link href="https://www.instagram.com/rftl5k/" target="_blank" className="social-icon lnv">
                            <FaInstagram style={{width: '20px', height: '20px'}}/>
                        </Nav.Link>
                        <Nav.Link href="https://karuna.lk/program/rftl.html" target="_blank" className="donate-now">
                            <Button className="Snd">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                     fill="none">
                                    <path
                                        d="M14.5133 6.53335L13.98 5.33335L12.8066 2.69335C12.4933 2.00002 12 1.43335 10.8666 1.43335H5.1333C3.99997 1.43335 3.50663 2.00002 3.1933 2.69335L2.01997 5.33335L1.48663 6.53335C1.17997 7.23335 1.34663 8.26002 1.85997 8.82668L6.42663 13.8534C7.2933 14.8067 8.70663 14.8067 9.5733 13.8534L14.14 8.82668C14.6533 8.26002 14.82 7.23335 14.5133 6.53335Z"
                                        fill="white"/>
                                </svg>
                                Donate
                            </Button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                {/* Right Side Social Icons */}
            </Container>
        </Navbar>
    )
}

export default SeparateMenu
