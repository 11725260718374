// LoginPage.js

import React, {useState} from 'react';

import {postRequest} from "../routes/Routes";
import {createUser} from "../auth/Auth";

import '../assets/css/loginPage.css';

import logo from '../assets/images/image.png';
import banner from '../assets/images/banner.png';
import bannerMob from '../assets/images/banner-mob.png';
import bannertab from '../assets/images/bannertabr.png';

const eyeIconSvg = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        style={{enableBackground: 'new 0 0 512 512'}}
        xmlSpace="preserve"
        className=""
    >
        <linearGradient id="a" x1="12.25" x2="12.25" y1="6.726" y2="18.483" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#67b270"/>
            <stop offset="1" stopColor="#4ca2cc"/>
        </linearGradient>
        <path
            fill="url(#a)"
            fillRule="evenodd"
            d="m22.832 7.584-1.807-.858-.428.903c-1.337 2.814-3.411 4.78-5.801 5.602l-.201.058a7.798 7.798 0 0 1-2.345.373c-3.433-.001-6.553-2.256-8.347-6.033l-.429-.903-1.807.858.43.903a14.585 14.585 0 0 0 2.172 3.337l-2.213 2.215 1.414 1.413 2.226-2.227a10.8 10.8 0 0 0 2.918 1.737l-.86 2.963 1.92.558.865-2.975c.56.099 1.13.154 1.71.154h.002c.555 0 1.1-.05 1.635-.14l.86 2.96 1.92-.557-.852-2.936a10.778 10.778 0 0 0 2.948-1.727l2.189 2.19 1.415-1.413-2.172-2.173c.852-.967 1.6-2.096 2.21-3.38z"
            clipRule="evenodd"
            opacity="1"
            dataOriginal="url(#a)"
            className=""
        />
    </svg>
);

const LoginPage = ({title, emailLabel, passwordLabel}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [rememberPassword, setRememberPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});

    const handleLogin = (event) => {
        setLoading(true);
        event.preventDefault();
        let values = {
            email: email,
            password: password,
            website: true,
            endpoint: '/api/login'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, errorMessage: response.data.message}));
                } else {
                    createUser(response.data.user).then(() => {
                        setError(values => ({...values, error: false, errorMessage: null}));
                        window.location.href = '/';
                    });
                }
            } else {
                setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
            }
            setLoading(false);
        });
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <img src={logo} alt="Logo" className="logo"/>
                <h2 className="login-title">{title}</h2>
                <p className="para-com">Please enter your credentials to sign in to your account</p>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label className="label">{emailLabel}:</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            className="input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Admin Email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="label">{passwordLabel}:</label>
                        <div className="password-input">
                            <input
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                className="input login-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Admin Password"
                                required
                            />
                            <span
                                className={`eye-icon ${showPassword ? 'visible' : ''}`}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {eyeIconSvg}
                            </span>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="remember-label">
                            <input
                                type="checkbox"
                                checked={rememberPassword}
                                onChange={() => setRememberPassword(!rememberPassword)}
                            />
                            Remember Password
                        </label>
                    </div>
                    {error.error &&
                    <div className="alert alert-danger" role="alert">
                        {error.errorMessage}
                    </div>}
                    <button type="submit" className="login-button">
                        {loading ?
                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                  aria-hidden="true"/>
                            : null
                        }
                        SIGN IN
                    </button>
                </form>
            </div>
            <div className="background-img">
                <picture>
                    <source media="(max-width: 767px)" srcSet={bannerMob} alt=""/>
                    <source media="(max-width: 1023px)" srcSet={bannertab} alt=""/>
                    <img src={banner} alt="background"/>
                </picture>
            </div>
        </div>
    );
};

export default LoginPage;
