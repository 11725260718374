import React from "react";
import BannerSmall from '../components/BannerSmall';
import HowItWorks from '../components/HowItWorks';
import RFTLRegister from '../components/IndividualRegistationForm';
import backgroundimg from '../assets/images/indiBookPc.jpg'
import backgroundimgMobile from '../assets/images/indiMob.jpg'

function individualbook() {
    return (
        <div>
            <BannerSmall
                backgroundImage={backgroundimgMobile}
                backgroundPCImage={backgroundimg}
                mainText="Individual Booking"
                paginationText="Home / Individual Booking"
            />
            <div className="mobile-booking-container">
                <HowItWorks/>
                <RFTLRegister/>
            </div>
        </div>
    )
}

export default individualbook

