import React, {useEffect, useState} from "react";
import logo from '../assets/images/logo.png';
import '../assets/css/IndividualBookingSummary.css';
import {loadBooking} from "../common/common";
import {useNavigate} from "react-router-dom";
import GroupBookSummary from "./GroupBookSummary";
import {downloadFile, postRequest} from "../routes/Routes";
import {toast} from "react-toastify";
import Loading from "../components/Loading";
import {getUser} from "../auth/Auth";

function IndividualBookingSummary() {
    const [bookingReference] = useState(decodeURI(window.location.href.split('/').at(-1))),
        [booking, setBooking] = useState(null),
        [loading, setLoading] = useState(false),
        [checkinLoading, setCheckinLoading] = useState(false),
        [user, setUser] = useState(null),
        navigate = useNavigate();

    useEffect(() => {
        loadBooking(bookingReference).then(data => {
            if (data.is_group) {
                data.member_count = 0;
                data.booking_t_shirts.forEach((tShirt) => {
                    data.member_count += tShirt.quantity;
                    if (tShirt.t_shirt_id) {
                        data[tShirt.t_shirt.size + "Count"] = tShirt.quantity;
                    } else {
                        data["noTshirtCount"] = tShirt.quantity;
                    }
                });
            }
            setBooking(data);
        }).catch(() => null);
        getUser().then((data) => {
            setUser(data);
        }).catch(() => {
            setUser(null);
        });
    }, []);

    const download = () => {
        setLoading(true);
        downloadFile('/api/booking/download/' + booking.reference).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    response.data.arrayBuffer().then(function (buffer) {
                        const url = URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `booking.pdf`;
                        link.click();
                        URL.revokeObjectURL(url);
                    });
                    setLoading(false);
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    };

    const checkin = () => {
        setCheckinLoading(true);
        let values = {
            token: user.token,
            endpoint: '/api/booking/checkin/' + booking.id
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setBooking({...response.data.booking});
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setCheckinLoading(false);
        });
    }

    if (booking) {
        if (booking.is_group) {
            return (
                <GroupBookSummary propBooking={booking} user={user}/>
            )
        } else {
            return (
                <div className="summer-wrap">
                    <img className="logo-pg" src={logo} alt=""/>
                    <div className="book-confirm ">
                        <span className="booking-confirm">Your Booking is confirmed!</span>
                        <span className="para-nor">{booking.full_name.split(" ")[0]}, thank you for registering for Run For Their Lives 2024 in aid of the Apeksha Cancer Hospital. Please note that all the proceeds from the event will be donated to the Apeksha Cancer Hospital in Maharagama</span>
                        <span className="para-bold">Please make sure you have the receipt on hand when receiving the T-shirt at the event.</span>
                    </div>
                    <div className="bill-sum-wrapp">
                        <div className="bill-head">
                            <div>
                                <span className="date">{(new Date(booking.created_at)).toDateString()}</span>
                                <span className="BillHead">Billing Summary</span>
                            </div>
                            <span className="BookingID">Booking ID : {booking.reference}</span>
                        </div>

                        <div className="flx-div">
                            <div className="sin">
                                <span className="cml-txt">Full Name:</span>
                                <span className="big-txt">{booking.full_name}</span>
                            </div>
                            <div className="sin">
                                <span className="cml-txt">Date Of Birth:</span>
                                <span className="big-txt">{booking.date_of_birth}</span>
                            </div>
                            <div className="sin">
                                <span className="cml-txt">Contact Number:</span>
                                <span className="big-txt">{booking.phone}</span>
                            </div>
                            <div className="sin">
                                <span className="cml-txt">Email Address:</span>
                                <span className="big-txt">{booking.email}</span>
                            </div>
                        </div>
                        <div className="flx-sp-0">
                            <div className="div-box">
                                <span className="sin-m">Tshirt Size</span>
                                <span
                                    className="sin-b">{booking.t_shirt ? booking.t_shirt.description : "No T-Shirt"}</span>
                            </div>

                            <div className="div-box bx">
                                <span className="sin-m">Payment Method</span>
                                <span className="sin-b">{booking.payment_type}</span>
                            </div>
                        </div>

                        <div className="pay-dil">
                            <div className="sin-col">
                                <span className="reg-n">Registration Fee</span>
                                <span className="reg-c">LKR 1600</span>
                            </div>
                            <div className="sin-col">
                                <span className="reg-n">Extra Donation</span>
                                <span className="reg-c">LKR {booking.donation - 1600}</span>
                            </div>
                        </div>

                        <div className="total">
                            <span className="name">Donation Amount</span>
                            <span className="fee">LKR {booking.donation}</span>
                        </div>

                        <div className="spc-div">
                            <span className="normal-pp">If you have any questions or inquiries, please don't hesitate to contact us via email <u>(rftl.charityrun@gmail.com)</u> or contact number (+94) 76 232 8714.</span>
                            <span className="thnks">Thank you for your contributions</span>
                            <span className="btm-nm">Rotaract Club Colombo Uptown (RCCU)</span>
                        </div>
                    </div>
                    <button className="dwn" onClick={() => download()}>
                        {loading ?
                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                  aria-hidden="true"/>
                            : null
                        }
                        DOWNLOAD RECEIPT
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M2.5 15.8346H17.5V17.5013H2.5V15.8346ZM10.8333 10.9776L15.8926 5.91839L17.0711 7.0969L10 14.168L2.92893 7.0969L4.10744 5.91839L9.16667 10.9776V1.66797H10.8333V10.9776Z"
                                fill="white"/>
                        </svg>
                    </button>
                    {user &&
                    <button className="dwn" onClick={() => checkin()} disabled={booking.checkin}
                            style={{marginTop: "2%", backgroundColor: "#0b5ed7"}}>
                        {checkinLoading ?
                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                  aria-hidden="true"/>
                            : null
                        }
                        CHECK IN
                    </button>
                    }
                    {booking.checkin &&
                    <span className="cpy" style={{color: "red"}}>Already Checked In</span>
                    }

                    <span className="cpy">@ {new Date().getFullYear()} Copyright | All rights reserved</span>

                    <div className="flx-ftr">
                        <button className="nav-btn" onClick={() => navigate("/")}>Home</button>
                        <button className="nav-btn" onClick={() => navigate("/about-us")}>About Us</button>
                        <button className="nav-btn" onClick={() => navigate("/events")}>Events</button>
                        <button className="nav-btn" onClick={() => navigate("/gallery")}>Gallery</button>
                        <button className="nav-btn" onClick={() => navigate("/contact-us")}>Contact Us</button>
                    </div>

                </div>
            )
        }
    } else {
        return (<Loading/>);
    }
}

export default IndividualBookingSummary

