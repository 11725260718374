import React from "react";
import '../assets/css/LeftParaRightImg.css'
import smallImg from '../assets/images/smallImg.png'
import smallImg2 from '../assets/images/smallImg2.png'

function LeftParaRightImg({ smallTopTxt,MainTopic, pataBtm1,pataBtm2, pataBtm3, rigtMobImg, rgtPcImg }) {
    return (
        <div className="main-wrapper-lpri">
            <div className="left">
                <span className="smallTagline">{smallTopTxt}</span>
                <span className="MainTAg">{MainTopic}</span>

                <p className="small-linePara">
                   {pataBtm1}
                   <br/>
                   <br/>
                   {pataBtm2}
                   <br/>
                   <br/>
                   {pataBtm3}

                </p>
            </div>
            <div className="right">
                <picture>
                        <source media="(max-width: 767px)" srcSet={rigtMobImg} alt=""/>
                        <source media="(max-width: 1023px)" srcSet={rigtMobImg} alt=""/>
                        <img src={rgtPcImg} alt="background" className="leftParaRightImg"/>
                    </picture>
            </div>
        </div>
    )
}

export default LeftParaRightImg