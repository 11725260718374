// Header.js

import React, {useEffect, useState} from 'react';
import {Button, Container, Nav, Navbar} from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import '../assets/css/Header.css';
import LOGO from '../assets/images/logo.png'
import backgroundimg from '../assets/images/bg.jpg'
import backgroundimgMobile from '../assets/images/mobileBackgroundHeader.jpg'
import {loadBookingStatus} from "../common/common";

const Header = () => {
    const sliderSettings = {
            dots: false,
            arrows: false,
            autoplay: false,
            infinite: true,
            pauseOnHover: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        },
        [bookingStatus, setBookingStatus] = useState(null),
        [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        loadBookingStatus().then((data) => {
            setBookingStatus(data);
        }).catch(() => {
            setBookingStatus(null);
        });
    }, []);

    const handleButtonClick = (e) => {
        if (bookingStatus && !bookingStatus.active) {
            e.preventDefault();
            setShowPopup(true);
        }
    }

    const handleClosePopup = () => {
        setShowPopup(false);
    }

    return (
        <header>
            <Navbar bg="dark" variant="dark" expand="lg" className="custom-navbar nv-height">
                <Container>
                    {/* Left Side Icon */}
                    <Navbar.Brand href="/">
                        <img
                            src={LOGO}
                            alt="Logo"
                            className="logo"
                            style={{marginRight: '10px'}}
                        />
                    </Navbar.Brand>
                    {/* Middle Navigation Menu Items */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto mdl-set">
                            <Nav.Link href="/" className="menu-item " activeClassName="active-pg">
                                Home
                            </Nav.Link>
                            <Nav.Link href="/about-us" className="menu-item" activeClassName="active-pg">
                                About Us
                            </Nav.Link>
                            <Nav.Link href="/events" className="menu-item" activeClassName="active-pg">
                                Events
                            </Nav.Link>
                            <Nav.Link href="/gallery" className="menu-item" activeClassName="active-pg">
                                Gallery
                            </Nav.Link>
                            <Nav.Link href="/contact-us" className="menu-item" activeClassName="active-pg">
                                Contact Us
                            </Nav.Link>
                        </Nav>
                        <Nav className="ml-auto rgt-set">
                            <Nav.Link href="#" className="follow-us lnv">
                                Follow Us
                            </Nav.Link>
                            <Nav.Link href="https://www.facebook.com/runfortheirlives/" target="_blank">
                                <FaFacebook style={{width: '20px', height: '20px'}}/>
                            </Nav.Link>
                            <Nav.Link href="https://www.instagram.com/rftl5k/" target="_blank"
                                      className="social-icon lnv">
                                <FaInstagram style={{width: '20px', height: '20px'}}/>
                            </Nav.Link>
                            <Nav.Link href="https://karuna.lk/program/rftl.html" target="_blank" className="donate-now">
                                <Button className="Snd">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                         fill="none">
                                        <path
                                            d="M14.5133 6.53335L13.98 5.33335L12.8066 2.69335C12.4933 2.00002 12 1.43335 10.8666 1.43335H5.1333C3.99997 1.43335 3.50663 2.00002 3.1933 2.69335L2.01997 5.33335L1.48663 6.53335C1.17997 7.23335 1.34663 8.26002 1.85997 8.82668L6.42663 13.8534C7.2933 14.8067 8.70663 14.8067 9.5733 13.8534L14.14 8.82668C14.6533 8.26002 14.82 7.23335 14.5133 6.53335Z"
                                            fill="white"/>
                                    </svg>
                                    Donate
                                </Button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    {/* Right Side Social Icons */}
                </Container>
            </Navbar>

            {/* Slick Carousel */}
            <Slider {...sliderSettings}>
                <div className="cont">
                    <picture>
                        <source media="(max-width: 767px)" srcSet={backgroundimgMobile} alt=""/>
                        <source media="(max-width: 1023px)" srcSet={backgroundimgMobile} alt=""/>
                        <img src={backgroundimg} alt="background"/>
                    </picture>
                    <div className="container-hdr">
                        <span
                            className="sp-span">- Charity Run 2024 organized by the Rotaract Club of Colombo Uptown</span>
                        <h1>RUN FOR <span className="highlighted-word">
              their
            </span>{' '} LIVES</h1>
                        <p>
                            Run for Their Lives (RFTL) is a 5 km charity run organized annually by the Rotaract Club of
                            Colombo Uptown with the sole purpose of raising funds for the purchasing of vital medicines
                            and medical equipment urgently required by the Apeksha Hospital - the National Cancer
                            Institute in Maharagama Sri Lanka.
                        </p>
                        <div className="button-sec-hdr">
                            <Button href={bookingStatus && bookingStatus.active ? '/individual-booking' : ""}
                                    onClick={(event) => handleButtonClick(event)} className="Snd">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                     fill="none">
                                    <path
                                        d="M7.09334 6.34085C7.035 6.33502 6.965 6.33502 6.90084 6.34085C5.5125 6.29419 4.41 5.15669 4.41 3.75669C4.41 2.32752 5.565 1.16669 7 1.16669C8.42917 1.16669 9.59 2.32752 9.59 3.75669C9.58417 5.15669 8.48167 6.29419 7.09334 6.34085Z"
                                        stroke="white" strokeWidth="1.5"/>
                                    <path
                                        d="M4.17667 8.49331C2.765 9.43831 2.765 10.9783 4.17667 11.9175C5.78084 12.9908 8.41167 12.9908 10.0158 11.9175C11.4275 10.9725 11.4275 9.43248 10.0158 8.49331C8.4175 7.42581 5.78667 7.42581 4.17667 8.49331Z"
                                        stroke="white" strokeWidth="1.5"/>
                                </svg>
                                Individual Booking
                            </Button>
                            <Button href={bookingStatus && bookingStatus.active ? '/group-booking' : ""}
                                    onClick={(event) => handleButtonClick(event)} className="Snd">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                     fill="none">
                                    <path
                                        d="M12 4.77332C11.96 4.76665 11.9133 4.76665 11.8733 4.77332C10.9533 4.73998 10.22 3.98665 10.22 3.05331C10.22 2.09998 10.9866 1.33331 11.94 1.33331C12.8933 1.33331 13.66 2.10665 13.66 3.05331C13.6533 3.98665 12.92 4.73998 12 4.77332Z"
                                        stroke="white" strokeWidth="1.5"/>
                                    <path
                                        d="M11.3133 9.62663C12.2266 9.77997 13.2333 9.61996 13.9399 9.14663C14.8799 8.51996 14.8799 7.4933 13.9399 6.86663C13.2266 6.3933 12.2066 6.23329 11.2933 6.39329"
                                        stroke="white" stokewidth="1.5" strokeLinejoin="round"/>
                                    <path
                                        d="M3.98001 4.77332C4.02001 4.76665 4.06667 4.76665 4.10667 4.77332C5.02667 4.73998 5.76001 3.98665 5.76001 3.05331C5.76001 2.09998 4.99334 1.33331 4.04001 1.33331C3.08668 1.33331 2.32001 2.10665 2.32001 3.05331C2.32667 3.98665 3.06001 4.73998 3.98001 4.77332Z"
                                        stroke="white" stokewidth="1.5"/>
                                    <path
                                        d="M4.66669 9.62663C3.75335 9.77997 2.74669 9.61996 2.04002 9.14663C1.10002 8.51996 1.10002 7.4933 2.04002 6.86663C2.75336 6.3933 3.77335 6.23329 4.68669 6.39329"
                                        stroke="white" stokewidth="1.5"/>
                                    <path
                                        d="M7.99997 9.75336C7.95997 9.74669 7.9133 9.74669 7.8733 9.75336C6.9533 9.72002 6.21997 8.96669 6.21997 8.03336C6.21997 7.08002 6.98664 6.31335 7.93997 6.31335C8.8933 6.31335 9.65997 7.08669 9.65997 8.03336C9.6533 8.96669 8.91997 9.72669 7.99997 9.75336Z"
                                        stroke="white" stokewidth="1.5"/>
                                    <path
                                        d="M6.05998 11.8534C5.11998 12.48 5.11998 13.5067 6.05998 14.1334C7.12665 14.8467 8.87331 14.8467 9.93998 14.1334C10.88 13.5067 10.88 12.48 9.93998 11.8534C8.87998 11.1467 7.12665 11.1467 6.05998 11.8534Z"
                                        stroke="white" stokewidth="1.5"/>
                                </svg>
                                Group Booking
                            </Button>
                        </div>
                    </div>
                </div>
            </Slider>
            {showPopup && (
                <div className="popupWindow">
                    <div className="popup-content">
                        <span className="popUpThanks">Registrations for RFTL 2024 are closed!</span>
                        <p>
                            If you still wish to participate in the event on 25th February, please drop a WhatsApp
                            message to <strong>074 202 5505</strong>. Thank you!
                        </p>
                        <button onClick={handleClosePopup} className="sendPop">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;
