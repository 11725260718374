import React from "react";
import BannerSmall from '../components/BannerSmall';
import GrpReg from '../components/GrpRegForm';
import '../assets/css/HowItWorks.css'

import grpBookBanMob from "../assets/images/grpBookBanMob.jpg";
import grpBookBanPc from "../assets/images/grpBookBanPc.jpg";

function individualbook() {
    return (
        <div>
            <BannerSmall
                backgroundImage={grpBookBanMob}
                backgroundPCImage={grpBookBanPc}
                mainText="Group Booking"
                paginationText="Home / Group Booking"

            />

            <div className="mobile-booking-container">
                <div className="howItWorks-Wrapper">
                    <span className="main-topic">How it works</span>

                    <div className="comp-flex-all">
                        <div className="Single-main-div">
                            <div className="number purpleNumber">
                                {/* to get purpole color rounds and line add, "purpleNumber" class with "number" class*/}
                                <span>1</span>
                            </div>
                            <div className="inner-flex">
                                <div className="main-txt">
                                    <span>Fill in the registration form for individual participant</span>
                                </div>
                                <div className="para">
                                    <span>Welcome to RFTL -2024. Fill in the registration form below if you are planning on participating in RFTL - 2024 Charity run as an individual participant.</span>
                                </div>
                            </div>
                        </div>

                        <div className="Single-main-div">
                            <div className="number purpleNumber">
                                <span>2</span>
                            </div>
                            <div className="inner-flex">
                                <div className="main-txt">
                                    <span>Select option for T-shirt & extra donations</span>
                                </div>
                                <div className="para">
                                    <span>Participant can select the T-shirt size out of the list & also 'No T-shirt'. Minimum donation amount is LKR 1600 with or without T-shirt.</span>
                                </div>
                            </div>
                        </div>

                        <div className="Single-main-div">
                            <div className="number purpleNumber">
                                <span>3</span>
                            </div>
                            <div className="inner-flex">
                                <div className="main-txt">
                                    <span>Proceed to checkout & Select payment method</span>
                                </div>
                                <div className="para">
                                    <span>Payment can be made through the WebXPay payments platform, which facilitates multiple forms of payment including credit cards, debit cards and e-wallets.</span>
                                </div>
                            </div>
                        </div>

                        <div className="Single-main-div">
                            <div className="number purpleNumber">
                                <span>4</span>
                            </div>
                            <div className="inner-flex">
                                <div className="main-txt">
                                    <span>Receipt will be sent to your inbox</span>
                                </div>
                                <div className="para">
                                    <span>Your receipt will be sent to your inbox shortly after registering. Please make sure you have the receipt on hand when receiving the T-shirt at the event.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <GrpReg/>
            </div>
        </div>
    )
}

export default individualbook

