import {getRequest} from "../routes/Routes";
import {toast} from 'react-toastify';

export const loadPartners = async () => {
    let partners = [];
    await getRequest('/api/partner').then(response => {
        if (response.status === 200) {
            partners = response.data.partners;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return partners;
};

export const loadGalleries = async () => {
    let gallery = [];
    await getRequest('/api/gallery').then(response => {
        if (response.status === 200) {
            gallery = response.data.gallery;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return gallery;
};

export const loadLimitedGalleries = async (limit) => {
    let gallery = [];
    await getRequest('/api/gallery/limit/' + limit).then(response => {
        if (response.status === 200) {
            gallery = response.data.gallery;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return gallery;
};

export const loadGalleryOnTags = async () => {
    let data = null;
    await getRequest('/api/gallery/tags').then(response => {
        if (response.status === 200) {
            data = response.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return data;
};

export const loadAvailableTShirts = async () => {
    let tShirts = [];
    await getRequest('/api/t-shirt/available').then(response => {
        if (response.status === 200) {
            tShirts = response.data.tShirts;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return tShirts;
};

export const loadBooking = async (reference) => {
    let booking = null;
    await getRequest('/api/booking/reference/' + reference).then(response => {
        if (response.status === 200) {
            booking = response.data.booking;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return booking;
};

export const loadBookingStatus = async (token) => {
    let bookingStatus = null;
    await getRequest('/api/booking-status', token).then(response => {
        if (response.status === 200) {
            bookingStatus = response.data.bookingStatus;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return bookingStatus;
};
