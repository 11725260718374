import React from "react";
import backgroundimg from '../assets/images/bg.jpg'
import backgroundimgMobile from '../assets/images/mobileBackgroundHeader.jpg'
import '../assets/css/SmallBanner.css'

import SeparateMenu from '../components/SeparateMenuBar'

function BannerSmall({ backgroundImage,backgroundPCImage, mainText, paginationText }) {
    return (
        
        <div className="banner-inner">
            <SeparateMenu/>
            <picture>
              <source media="(max-width: 767px)" srcSet={backgroundImage} alt=""/>
              <source media="(max-width: 1023px)" srcSet={backgroundImage} alt=""/>
              <img src={backgroundPCImage} alt="background" className="bgSub"/>
            </picture>
            <div className="absTxt">
                <span className="main-txt">
                {mainText}
                </span>
                <span className="pagination">{paginationText}</span>
            </div>
        </div>
    )
}

export default BannerSmall