export const createUser = async (user) => {
    user.time = new Date();
    localStorage.setItem('user', JSON.stringify(user));
};

export const getUser = async (redirect) => {
    let user = JSON.parse(localStorage.getItem('user')),
        currentDate = new Date();

    // if (!(new Date(user.time) > currentDate.setHours(currentDate.getHours() - 3))) {
    //     await deleteUser();
    //     window.location.href = '/';
    // }

    return JSON.parse(localStorage.getItem('user'));
};

export const deleteUser = async () => {
    localStorage.removeItem('user');
};
